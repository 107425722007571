import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import { Flex, Caption1, Caption2, Input } from '@components/atoms'
import styled from 'styled-components'
import { STORAGE_URL } from '@consts/'
import { convertToPricingComma } from '@utils/format'
import { IncreaseTextBox } from '@components/molecules'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import { copyGrayImg } from '@images'
import { search_img } from '@images/index'

const TableBox = styled.div`
  width: 100%;
  height: 936px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;
  padding: 50px;

  thead > tr > th {
    background-color: transparent;
    color: #646464;
  }

  thead > tr > th:nth-child(3),
  th:nth-child(4),
  th:nth-child(5),
  th:nth-child(6),
  th:nth-child(7),
  th:nth-child(8) {
    text-align: center;
    min-width: 88px;
  }
`

const TracksSnsLikeTableChart = ({ snsType, type = 'aritst' }) => {
  const { trendStore } = useStore()

  const { artistTrendTrackList, companyTrendTrackList } = trendStore
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    if (type === 'company') {
      if (tableData.length === 0 && companyTrendTrackList)
        setTableData(companyTrendTrackList)
    }
    else if (tableData.length === 0 && artistTrendTrackList)
      setTableData(artistTrendTrackList)
  }, [tableData, artistTrendTrackList, companyTrendTrackList])

  const sortHandler = (a, b, sortOrder) => {
    if (a < b) {
      return -1
    }
    else if (a > b) {
      return 1
    }
    else {
      return 0
    }
  }

  return (
    <Flex type="column" style={{ marginTop: '50px' }}>
      <Flex
        style={
          {
            width: '410px',
            borderRadius: '2px',
            border: '1px solid #949494',
            padding: '8px 12px',
            marginBottom: '18px',
          }
        }
      >
        <img
          src={search_img}
          alt="search_img"
          style={{ width: '24px', height: '24px' }}
        />
        <Input
          style={
            {
              width: '362px',
              height: '24px',
              border: 'none',
            }
          }
          placeholder="검색어를 입력해주세요"
          onChange={
            e => {
              if (type === 'company') {
                setTableData(
                  companyTrendTrackList.filter(item =>
                    item.title
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()),
                  ),
                )
              }
              else {
                setTableData(
                  artistTrendTrackList.filter(item =>
                    item.title
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase()),
                  ),
                )
              }
            }
          }
        />
      </Flex>
      <TableBox>
        <Table
          className="tracks_like_table"
          pagination={false}
          rowKey="_id"
          dataSource={tableData.slice() || []}
          style={
            {
              width: '100%',
            }
          }
          scroll={{ y: 800 }}
          columns={
            [
              {
                title: '#',
                key: '_id',
                dataIndex: '_id',
                render: (record, text, index) => {
                  return index + 1
                },
                width: '90px',
              },
              {
                title: '트랙명',
                key: 'title',
                dataIndex: 'title',
                sorter: (a, b, sortOrder) => {
                  return sortHandler(a?.title, b?.title)
                },
                render: (text, record) => {
                  return (
                    <Flex>
                      <img
                        src={STORAGE_URL + record?.albumInfo?.image64Path}
                        alt="cover_img"
                        style={
                          {
                            width: '36px',
                            height: '36px',
                            borderRadius: '6px',
                            marginRight: '16px',
                          }
                        }
                      />
                      <Flex type="column">
                        <Caption2
                          style={
                            {
                              fontSize: 10,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                            }
                          }
                          align="left"
                          color="#949494"
                        >
                          {record?.albumInfo?.title}
                        </Caption2>
                        <Caption2
                          style={
                            {
                              fontSize: 12,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                            }
                          }
                          align="left"
                        >
                          {text}
                        </Caption2>
                      </Flex>
                    </Flex>
                  )
                },
                width: '100%',
              },
              snsType !== 'melon'
                ? {}
                : {
                  title: <Caption2 color="#949494">Listen</Caption2>,
                  key: `listenerCountInfo.melonTotalStreamingCount`,
                  dataIndex: `listenerCountInfo.melonTotalStreamingCount`,
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                      a?.listenerCountInfo?.melonTotalStreamingCount,
                      b?.listenerCountInfo?.melonTotalStreamingCount,
                    )
                  },
                  render: (text, record) => {
                    return (
                      <Flex type="column">
                        <Caption1>
                          {
                            convertToPricingComma(
                            record?.listenerCountInfo?.melonTotalStreamingCount,
                            )
                          }
                        </Caption1>
                        <IncreaseTextBox
                          data={
                            record?.listenerCountInfo?.increaseValue
                              ?.melonTotalStreamingCount
                          }
                          style={{ alignSelf: 'center' }}
                        />
                      </Flex>
                    )
                  },
                  width: '88px',
                },
              {
                title: <Caption2 color="#949494">Listener</Caption2>,
                key: `listenerCountInfo[${snsType}]`,
                dataIndex: `listenerCountInfo[${snsType}]`,
                sorter: (a, b, sortOrder) => {
                  return sortHandler(
                  a?.listenerCountInfo[snsType],
                  b?.listenerCountInfo[snsType],
                  )
                },
                render: (text, record) => {
                  return (
                    <Flex type="column">
                      <Caption1>
                        {
                          convertToPricingComma(
                        record?.listenerCountInfo[snsType],
                          )
                        }
                      </Caption1>
                      <IncreaseTextBox
                        data={record?.listenerCountInfo?.increaseValue[snsType]}
                        style={{ alignSelf: 'center' }}
                      />
                    </Flex>
                  )
                },
                width: '88px',
              },
              snsType === 'youtube'
                ? {}
                : {
                  title: <Caption2 color="#949494">Rank</Caption2>,
                  key: `rankInfo[${snsType}]`,
                  dataIndex: `rankInfo[${snsType}]`,
                  sorter: (a, b, sortOrder) => {
                    return sortHandler(
                      a?.rankInfo[snsType],
                      b?.rankInfo[snsType],
                    )
                  },
                  render: (text, record) => {
                    return (
                      <Flex type="column">
                        <Caption1>
                          {convertToPricingComma(record?.rankInfo[snsType])}
                        </Caption1>

                        <IncreaseTextBox
                          data={record?.rankInfo?.increaseValue[snsType]}
                          style={{ alignSelf: 'center' }}
                        />
                      </Flex>
                    )
                  },
                  width: '88px',
                },
              {
                title: <Caption2 color="#949494">Like</Caption2>,
                key: `likeCountInfo[${snsType}]`,
                dataIndex: `likeCountInfo[${snsType}]`,
                sorter: (a, b, sortOrder) => {
                  return sortHandler(
                  a?.likeCountInfo[snsType],
                  b?.likeCountInfo[snsType],
                  )
                },
                render: (text, record) => {
                  return (
                    <Flex type="column">
                      <Caption1>
                        {convertToPricingComma(record?.likeCountInfo[snsType])}
                      </Caption1>
                      <IncreaseTextBox
                        data={record?.likeCountInfo?.increaseValue[snsType]}
                        style={{ alignSelf: 'center' }}
                      />
                    </Flex>
                  )
                },
                width: '88px',
              },
              {
                title: '플램인',
                key: `trackUrl`,
                dataIndex: `trackUrl`,
                align: 'right',
                render: text => {
                  return (
                    <Flex
                      justify="flex-end"
                      onClick={
                        () => {
                          window.open(text)
                        }
                      }
                    >
                      <Flex
                        justify="center"
                        align="center"
                        style={
                          {
                            width: 28,
                            height: 28,
                            borderRadius: '50%',
                            backgroundColor: '#f4f4f4',
                            cursor: 'pointer',
                          }
                        }
                      >
                        <img
                          src={copyGrayImg}
                          width={20}
                          height={20}
                          alt="copy"
                        />
                      </Flex>
                    </Flex>
                  )
                },
                width: '88px',
              },
            ]
          }
        />
      </TableBox>
    </Flex>
  )
}

export default observer(TracksSnsLikeTableChart)
