import React, { useEffect, useMemo, useState } from 'react'
import {
  InsightRevenueTrackTemplate,
  RootTemplate,
} from '@components/templates'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import QueryString from 'qs'
import { IS_DEV } from '@consts'

interface Props {
  logout: Function;
  user: Object;
}

const InsightRevenueTrackPage = ({ user, logout, match }: Props) => {
  const { authStore, revenueStore, artistStore } = useStore()
  const { currentUser } = authStore
  const { artistDetail } = artistStore
  const {
    artistRecentMonth,
    companyRecentMonth,
    artistTrackRevenueExpectedList,
    trackRevenueData,
  } = revenueStore

  const location = useLocation()
  const query = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  const artistId = query?.artistId
  const isCompany = query?.isCompany === '1'
  const isCompanyData = currentUser?.type === 'company' && isCompany

  const fetchArtistRecentMonth =
    revenueStore.fetchArtistRecentMonth || (() => {})
  const fetchCompanyRecentMonth =
    revenueStore.fetchCompanyRecentMonth || (() => {})
  const fetchRevenueArtistTrackData =
    revenueStore.fetchRevenueArtistTrackData || (() => {})
  const fetchRevenueArtistTrackExpected =
    revenueStore.fetchRevenueArtistTrackExpected || (() => {})
  const fetchRevenueCompanyTrackExpected =
    revenueStore.fetchRevenueCompanyTrackExpected || (() => {})
  const fetchRevenueCompanyTrackDetailData =
    revenueStore.fetchRevenueCompanyTrackDetailData || (() => {})

  const recentDate = useMemo(() => {
    if (!artistRecentMonth) return moment()
    return moment().set({
      year: `20${Math.floor(artistRecentMonth / 100)}`,
      month: (artistRecentMonth % 100) - 1,
    })
  }, [artistRecentMonth])

  const [currentMonth, setCurrentMonth] = useState(null)

  const handleMonth = value => {
    setCurrentMonth(moment(value))
  }

  useEffect(() => {
    if (artistRecentMonth) {
      setCurrentMonth(
        moment().set({
          year: `20${Math.floor(artistRecentMonth / 100)}`,
          month: (artistRecentMonth % 100) - 1,
        }),
      )
    }
    else if (companyRecentMonth) {
      setCurrentMonth(
        moment().set({
          year: `20${Math.floor(companyRecentMonth / 100)}`,
          month: (companyRecentMonth % 100) - 1,
        }),
      )
    }
  }, [artistRecentMonth, companyRecentMonth])

  useEffect(() => {
    // if(IS_DEV) {
    //   /* dizzy blue */
    //   currentUser.artistId = '5f72c7abb32c03496ea23aa1'
    // }

    if (currentUser) {
      if (isCompanyData && currentUser?.companyId) {
        fetchCompanyRecentMonth(currentUser?.companyId)
      }
      else {
        fetchArtistRecentMonth(currentUser?.artistId)
      }
    }
    if (currentUser && currentMonth && currentMonth && match.params.id) {
      /* init 로직 전에 변수 초기화 */
      revenueStore.artistTrackRevenueExpectedList = []
      revenueStore.trackRevenueData = []

      if (!isCompanyData && currentUser?.artistId) {
        fetchRevenueArtistTrackData(currentUser?.artistId, match.params.id, {
          __year: currentMonth.format('YY'),
        })
        // fetchRevenueArtistTrackExpected(currentUser?.artistId, match.params.id)
      }
      else if (isCompanyData && currentUser?.companyId) {
        fetchRevenueCompanyTrackDetailData(
          currentUser?.companyId,
          match.params.id,
          {
            __year: currentMonth.format('YY'),
          },
        )
        // fetchRevenueCompanyTrackExpected(
        //   currentUser?.companyId,
        //   match.params.id,
        // )
      }
    }
  }, [
    artistId,
    match,
    currentUser,
    currentMonth,
    fetchArtistRecentMonth,
    fetchCompanyRecentMonth,
    fetchRevenueArtistTrackData,
    fetchRevenueCompanyTrackDetailData,
    // fetchRevenueArtistTrackExpected,
    // fetchRevenueCompanyTrackExpected,
  ])

  return (
    <RootTemplate user={user} logout={logout} artist={artistDetail}>
      <InsightRevenueTrackTemplate
        trackRevenueData={trackRevenueData}
        artistTrackRevenueExpectedList={artistTrackRevenueExpectedList}
        currentMonth={currentMonth}
        handleMonth={handleMonth}
        recentDate={recentDate}
        isCompany={isCompany}
      />
    </RootTemplate>
  )
}

export default observer(InsightRevenueTrackPage)
