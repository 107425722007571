import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Flex, Text, H3, H5, H4, Container, H6 } from '@components/atoms'

import { ChartDivisionBox, IncreaseTextBox } from '@components/molecules'
import {
  TrackLikeInfoChart,
  DailyTrackLikeTableChart,
  StreamingListenLikeCard,
} from '@components/organisms'
import { dateYMDMM, convertToPricingComma } from '@utils/format'
import { STORAGE_URL } from '@consts/'
import { useHistory } from 'react-router-dom'
import { useStore } from '@utils/hooks'
import TrackGenderInfoChart from '@components/organisms/Chart/TrackGenderInfoChart'

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`
const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
  padding: 50px 50px 50px 0;
`

const SectionTitle = styled(Flex)`
  flex-direction: column;
  text-align: left;
  width: 100%;

  margin-bottom: 30px;
`

const MusicInfo = styled(Flex)`
  height: 160px;
  margin: 0 0 30px 0;

  img {
    width: 160px;
    height: 160px;
    border-radius: 6px;
    margin-right: 30px;
  }
`

export const InsightCompanyTrackTemplate = ({ trackId }) => {
  const { trendStore, authStore } = useStore()
  const history = useHistory()

  const [periodType, setPeriodType] = useState({
    label: '지난 28일',
    value: 'monthly',
  })

  const companyTrackTrend = trendStore?.companyTrackTrend

  useEffect(() => {
    // const query = qs.parse(location.search, {
    //   ignoreQueryPrefix: true,
    //   // 문자열 맨 앞의 ?를 생력
    // })
    let __periodDays = 28
    if (periodType.value === 'daily') {
      __periodDays = 1
    }
    else if (periodType.value === 'weekly') {
      __periodDays = 7
    }
    else if (periodType.value === 'monthly') {
      __periodDays = 28
    }
    else if (periodType.value === '3months') {
      __periodDays = 90
    }
    else if (periodType.value === 'year') {
      __periodDays = 365
    }
    else if (periodType.value === 'all') {
      __periodDays = 365 * 10
    }

    if (authStore?.currentUser?.companyId) {
      trendStore.fetchCompanyTrackTrend(
        authStore?.currentUser?.companyId,
        trackId,
        { __periodDays },
      )
    }
    return () => {}
  }, [trackId, trendStore, authStore, periodType, location])

  const albumInfo = companyTrackTrend?.albumInfo || null
  const rankInfo = companyTrackTrend?.rankInfo || null
  const likeCountInfo = companyTrackTrend?.likeCountInfo || null
  const listenerInfo = companyTrackTrend?.listenerInfo || null
  return (
    <Content>
      <Flex style={{ justifyContent: 'space-between' }}>
        <Flex type="column">
          <MusicInfo>
            <img src={STORAGE_URL + albumInfo?.image512Path} alt="album_img" />
            <Flex type="column">
              <H4 align="left" color="#949494">
                {albumInfo?.title}
              </H4>
              <H3 align="left" style={{ width: '880px' }}>
                {companyTrackTrend?.title}
              </H3>
              <Flex style={{ marginTop: 4 }}>
                <H6>{`권리사 : ${albumInfo?.planningCompanyList[0]?.name}`}</H6>
                <H6>
                  &nbsp;&nbsp;&nbsp;
                  {`유통사 : ${albumInfo?.releaseCompanyList[0]?.name}`}
                </H6>
              </Flex>
            </Flex>
          </MusicInfo>
          <SectionTitle>
            <H5 align="left" color="#949494">
              Total
            </H5>
            <Flex style={{ gap: '20px' }}>
              <Flex align="center">
                <H4 type="bold">
                  {
                    `${convertToPricingComma(
                    listenerInfo?.today?.streamingTotal,
                    ) || 0} Listen`
                  }
                </H4>
                <IncreaseTextBox
                  data={listenerInfo?.increaseValue?.streamingTotal || 0}
                  style={{ marginLeft: '12px' }}
                />
              </Flex>
              <Flex align="center">
                <H4 type="bold">
                  {
                    `${convertToPricingComma(likeCountInfo?.today?.total)
                    || 0} Like`
                  }
                </H4>
                <IncreaseTextBox
                  data={likeCountInfo?.increaseValue?.total || 0}
                  style={{ marginLeft: '12px' }}
                />
              </Flex>
            </Flex>
          </SectionTitle>
          <Flex style={{ gap: '40px' }}>
            <a href={companyTrackTrend?.melonUrl}>
              <StreamingListenLikeCard
                streamingType="melon"
                listenUnit={'Listener'}
                listenData={listenerInfo?.today?.melon}
                increaseListenData={listenerInfo?.increaseValue?.melon}
                melonTotalStreamingCount={
                  listenerInfo?.today?.melonTotalStreamingCount
                }
                increaseMelonTotalStreamingCount={
                  listenerInfo?.increaseValue?.melonTotalStreamingCount
                }
                likeData={likeCountInfo?.today?.melon}
                increaseLikeData={likeCountInfo?.increaseValue?.melon}
              />
            </a>
            <a href={companyTrackTrend?.youtubeUrl}>
              <StreamingListenLikeCard
                streamingType="youtube"
                listenData={listenerInfo?.today?.youtube}
                increaseListenData={listenerInfo?.increaseValue?.youtube}
                likeData={likeCountInfo?.today?.youtube}
                increaseLikeData={likeCountInfo?.increaseValue?.youtube}
                onClick={
                  () => {
                    if (authStore?.currentUser?.companyId) {
                    // history.push(`/insight/company/${authStore?.currentUser?.companyId}/track/youtube/${trackId}`)
                      window.open(
                        `/insight/company/${authStore?.currentUser?.companyId}/track/youtube/${trackId}`,
                      )
                    }
                  }
                }
              />
            </a>

            <StreamingListenLikeCard
              streamingType="spotify"
              listenData={listenerInfo?.today?.spotify}
              increaseListenData={listenerInfo?.increaseValue?.spotify}
              likeData={rankInfo?.today?.spotify}
              increaseLikeData={rankInfo?.increaseValue?.spotify}
            />
          </Flex>
        </Flex>
        <Flex type="column" style={{ width: '258px', margin: '40px 0 0 0' }}>
          <ChartDivisionBox
            options={
              [
                { label: '전체', value: 'all' },
                { label: '지난 1일', value: 'daily' },
                { label: '지난 7일', value: 'weekly' },
                { label: '지난 2일', value: 'monthly' },
              ]
            }
            value={periodType}
            onChange={value => setPeriodType(value)}
          />
        </Flex>
      </Flex>
      <TrackLikeInfoChart
        dailyListData={companyTrackTrend?.listenerInfo?.dailyList || []}
      />
      <TrackGenderInfoChart
        ageData={companyTrackTrend?.listenerAgeInfo || []}
        genderData={companyTrackTrend?.listenerGenderInfo || {}}
      />
      <DailyTrackLikeTableChart
        tableData={companyTrackTrend?.dailyDetailList || []}
      />
    </Content>
  )
}
