import React, { useEffect, useMemo, useState } from 'react'
import {
  InsightRevenueAdjacencyTemplate,
  InsightRevenueCopyRightTemplate,
  InsightRevenuePerformanceTemplate,
  InsightRevenueTemplate,
  RootTemplate,
} from '@components/templates'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import moment from 'moment'
import { NoRevenue, RevenueUploadModal } from '@components/organisms'
import { useHistory, useLocation } from 'react-router-dom'
import QueryString from 'qs'

interface Props {
  logout: Function;
  user: Object;
}

const InsightRevenuePage = ({ user, logout }: Props) => {
  const history = useHistory()

  const location = useLocation()
  const query = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })
  const type = query?.type
  const { authStore, revenueStore, artistStore } = useStore()
  const { currentUser } = authStore
  const {
    artistRevenueData,
    artistRevenueExpectedList,
    artistRecentMonth,
    // revenueType: type,
  } = revenueStore
  const { artistDetail } = artistStore

  const setType = revenueStore.setRevenueType || (() => {})
  const fetchArtistRecentMonth =
    revenueStore.fetchArtistRecentMonth || (() => {})
  const fetchRevenueAristData = revenueStore.fetchRevenueAristData || (() => {})
  const fetchRevenueArtistExpected =
    revenueStore.fetchRevenueArtistExpected || (() => {})

  const [currentYear, setCurrentYear] = useState(null)
  const [showUpload, setShowUpload] = useState(false)

  const recentDate = useMemo(() => {
    if (!artistRecentMonth) return moment()
    return moment().set({
      year: `20${Math.floor(artistRecentMonth / 100)}`,
      month: (artistRecentMonth % 100) - 1,
    })
  }, [artistRecentMonth])

  useEffect(() => {
    if (artistRecentMonth) {
      setCurrentYear(
        moment().set({
          year: `20${Math.floor(artistRecentMonth / 100)}`,
          month: (artistRecentMonth % 100) - 1,
        }),
      )
    }
  }, [artistRecentMonth, setShowUpload])

  const handleYear = value => {
    setCurrentYear(moment(value))
  }

  useEffect(() => {
    if (currentUser?.artistId) {
      if (!currentYear) fetchArtistRecentMonth(currentUser?.artistId)

      if (currentYear) {
        fetchRevenueAristData(currentUser?.artistId, {
          __year: currentYear.format('YY'),
          __month: currentYear.format('YYMM'),
        })

        // fetchRevenueArtistExpected(currentUser?.artistId)
      }
    }
  }, [
    currentUser,
    currentYear,
    fetchRevenueAristData,
    fetchArtistRecentMonth,
    // fetchRevenueArtistExpected,
  ])

  useEffect(() => {
    if (!query?.type) {
      setType(null)
    }
    else {
      setType(query?.type)
    }
    // return () => {
    //   setType(null)
    // }
    // eslint-disable-next-line
  }, [query.type])

  useEffect(() => {
    if (
      currentUser
      && currentUser['type'] !== 'company'
      && !currentUser?.artistId
    ) {
      history.push('/artist')
    }
  }, [currentUser, history])

  return (
    <RootTemplate user={user} logout={logout} artist={artistDetail}>
      <RevenueUploadModal
        show={showUpload}
        handleClose={() => setShowUpload(false)}
      />
      {
        artistRecentMonth === 'none' ? (
          <NoRevenue typeToggle={() => setShowUpload(true)} />
        ) : (
          <>
            {
              type === 'adjacency' ? (
                <InsightRevenueAdjacencyTemplate
                  artistRevenueData={artistRevenueData}
                  currentYear={currentYear}
                  handleYear={handleYear}
                  artistRevenueExpectedList={artistRevenueExpectedList}
                />
              ) : type === 'copyRight' ? (
                <InsightRevenueCopyRightTemplate
                  artistRevenueData={artistRevenueData}
                  currentYear={currentYear}
                  handleYear={handleYear}
                  artistRevenueExpectedList={artistRevenueExpectedList}
                />
              ) : type === 'performanceRight' ? (
                <InsightRevenuePerformanceTemplate
                  artistRevenueData={artistRevenueData}
                  currentYear={currentYear}
                  handleYear={handleYear}
                  artistRevenueExpectedList={artistRevenueExpectedList}
                />
              ) : (
                <InsightRevenueTemplate
                  artistRevenueData={artistRevenueData}
                  artistRevenueExpectedList={artistRevenueExpectedList}
                  currentYear={currentYear}
                  handleYear={handleYear}
                  handleType={
                    value => {
                      history.push(`/insight/revenue?type=${value}`)
                    }
                  }
                  recentDate={recentDate}
                />
              )
            }
          </>
        )
      }
    </RootTemplate>
  )
}

export default observer(InsightRevenuePage)
