import React, { useCallback, useEffect, useMemo } from 'react'
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from 'recharts'
import { CheckTextBox } from '@components/molecules'
import { Caption1, Caption2, Flex } from '@components/atoms'

import styled from 'styled-components'
import { convertToPricingComma } from '@utils/format'
import { useStore } from '@utils/hooks'
import { observer } from 'mobx-react'
import { v4 } from 'uuid'
import CheckBoxModal from '../Modal/CheckBoxModal'

const COLORS = [
  '#FC0211',
  '#1877F2',
  '#FF9742',
  '#FDB624',
  '#18D63B',
  '#761CF5',
  '#3B3BFD',
  '#FC0211',
  '#2E1C7F',
  '#FF2D55',
  '#242424',
  '#20B6E6',
  '#D4D4D4',
  '#EA4653',
]

const CompanyRevenueAdjacencyStatsChart = ({ currentYear }) => {
  const { revenueStore } = useStore()

  const {
    companyArtistList,
    companyAnnualRevenueData,
    selectedCompanyAristId: filterMedia,
    companyRevenueExpectedList,
    companyRecentMonth,
  } = revenueStore

  const isRecentYear =
    `20${`${companyRecentMonth}`?.slice(0, 2)}` === currentYear?.format('YYYY')

  const setFilterMedia = revenueStore.updateSelectedCompanyAristId || (() => {})
  const fetchRevenueCompanyExpected =
    revenueStore.fetchRevenueCompanyExpected || (() => {})

  const companyArtistExpectedRevenueList =
    companyRevenueExpectedList?.companyArtistExpectedRevenueList || []

  const data =
    companyAnnualRevenueData?.revenueInfo?.companyMonthlyRevenueInfo || []

  const parseExpectedData = useMemo(() => {
    let parseData = []

    parseData = companyArtistExpectedRevenueList?.map(artist =>
      artist.expectedRevenueList?.map(item => ({
        ...item,
        monthlyAdjacencyFee: item.adjacencyFee,
        salesMonth: item.month,
        artistId: artist.artistId,
      })),
    )

    return parseData
  }, [companyArtistExpectedRevenueList])

  const renderExpectedData = useCallback(() => {
    if (isRecentYear) {
      if (parseExpectedData?.length < 1) {
        return [
          <Line
            strokeDasharray="5 5"
            stroke="#242424"
            data={parseExpectedData}
            name="전체"
            legendType="none"
            dataKey="monthlyAdjacencyFee"
          />,
        ]
      }
      else {
        return parseExpectedData?.map((revenuInfo, index) => (
          <Line
            strokeDasharray="5 5"
            key={revenuInfo.artistId}
            stroke={COLORS[index || 0]}
            name={revenuInfo.artistId}
            data={revenuInfo}
            legendType="none"
            dataKey="monthlyAdjacencyFee"
          />
        ))
      }
    }
    else {
      return []
    }
  }, [parseExpectedData])

  // useEffect(() => {
  //   const __artistIdList = filterMedia.join(',')
  //   fetchRevenueCompanyExpected({ __artistIdList })
  // }, [filterMedia])

  return (
    <ChartWrapper type="column">
      <Flex style={{ zIndex: 2, margin: '4px 0' }}>
        <CheckBoxModal
          title="아티스트"
          onReset={() => setFilterMedia([])}
          content={
            <>
              <CheckTextBox
                checked={filterMedia.length === 0 ? true : false}
                onClick={() => setFilterMedia([])}
                text="전체"
              />
              {
companyArtistList?.map(artist => (
  <CheckTextBox
    key={artist?._id}
    checked={filterMedia.includes(artist?._id) ? true : false}
    onClick={
      () => {
        setFilterMedia(
          filterMedia.findIndex(e => e === artist?._id) > -1
            ? filterMedia.filter(e => e !== artist?._id)
            : filterMedia.concat(artist?._id),
        )
      }
    }
    text={artist?.name}
  />
))
              }
            </>
          }
        />
      </Flex>
      <ResponsiveContainer width="100%" height={438}>
        <ComposedChart
          margin={
            {
              top: 20,
              right: 0,
              left: 50,
            }
          }
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
            dataKey="salesMonth"
            tickFormatter={
              tick => {
                const strTick = `${tick}`
                return `${strTick[2]}${strTick[3]}월`
              }
            }
            allowDuplicatedCategory={false}
          />
          <YAxis
            dataKey="monthlyAdjacencyFee"
            domain={['auto', 'auto']}
            unit="원"
            tickFormatter={e => convertToPricingComma(e)}
          />
          <Tooltip
            content={
              ({ active, payload, label }) => (
                <CustomTooltip
                  active={active}
                  payload={payload}
                  label={label}
                  companyArtistList={companyArtistList}
                />
              )
            }
          />
          {/* <Tooltip /> */}
          <Legend
            verticalAlign="top"
            layout="horizontal"
            align="right"
            content={
              ({ payload }) => {
                const payloads = {}
                payload.forEach(item => {
                  payloads[item?.dataKey || v4()] = item
                })
                const _payload = Object.keys(payloads).map(key => payloads[key])
                return (
                  <Flex align="center" justify="flex-end" gap="20px">
                    {
                      _payload.map(item => (
                        <Flex align="center" gap="4px" key={item.id}>
                          <div
                            style={
                              {
                                width: 10,
                                height: 10,
                                border: `2px solid ${item.color}`,
                                borderRadius: '50%',
                              }
                            }
                          />
                          <Caption1>
                            {' '}
                            {
                              `${companyArtistList?.find(
                          artist => artist._id === item?.value,
                        )?.name || '전체'}`
                            }
                          </Caption1>
                        </Flex>
                      ))
                    }
                  </Flex>
                )
              }
            }
          />
          {
companyAnnualRevenueData?.revenueInfoList?.length < 1 && (
              <Line
                stroke="#242424"
                data={data}
                name="전체"
                legendType="none"
                dataKey="monthlyAdjacencyFee"
              />
            )
          }
          {
companyAnnualRevenueData?.revenueInfoList?.map(
            (revenuInfo, index) => (
              <Line
                key={revenuInfo.artistId}
                stroke={COLORS[index || 0]}
                name={revenuInfo.artistId}
                data={revenuInfo?.companyMonthlyRevenueInfo}
                legendType="none"
                dataKey="monthlyAdjacencyFee"
              />
            ),
          )
          }
          {renderExpectedData()}
        </ComposedChart>
      </ResponsiveContainer>
    </ChartWrapper>
  )
}

export default observer(CompanyRevenueAdjacencyStatsChart)

const ToolTipBox = styled(Flex)`
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;

  p {margin: 5px 0;}
  p:first-child {margin 0;}
`

const CustomTooltip = ({ active, payload, label, companyArtistList }) => {
  const strTick = `${label}`
  const customLabel = `${strTick[0]}${strTick[1]}년 ${strTick[2]}${strTick[3]}월`

  if (active) {
    const _uniquedData = {}

    return (
      <ToolTipBox type="column">
        <Caption2 type="Bold" align="left">{`${customLabel}`}</Caption2>
        {
payload?.map((data, index) => {
  if (_uniquedData[data.name]) {
    return ''
  }
  _uniquedData[data.name] = true
  return (
    <Caption2 align="left" color={data.stroke} key={`tooltip-${index}`}>
      {
        `${companyArtistList?.find(artist => artist._id === data.name)
                ?.name || '전체'} : ${data
                && convertToPricingComma(
                  Math.floor(data?.payload?.monthlyAdjacencyFee),
                )}원`
      }
    </Caption2>
  )
})
        }
      </ToolTipBox>
    )
  }
  return null
}

const ChartWrapper = styled(Flex)`
  width: 100%;
  height: 500px;
  padding: 12px 33px 8px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #fff;

  margin-bottom: 38px;
`
