import { observable } from 'mobx'
import { STORAGE_URL, DOMAIN_URL } from '@consts'
import { AlbumModel, ArtistModel } from '.'

export default class TrackModel {
  /* Database fields */
  @observable _id

  @observable isTitle
  @observable no
  @observable title
  @observable titleEng
  @observable musicVideoInfoList

  @observable audioOriginalPath
  @observable audioFilename

  @observable youtubeUrl
  @observable ISRC
  @observable UCI

  @observable releaseArtistIdList
  @observable featuringArtistIdList
  @observable lyricstArtistIdList
  @observable composerArtistIdList
  @observable arrangerArtistIdList
  @observable youtubeUrlList

  @observable lyrics
  @observable mainGenre
  @observable subGenre

  //
  @observable spotifyUrl
  @observable youtubeMusicUrl
  @observable melonDeepLinkUrl
  @observable melonUrl
  @observable genieUrl
  @observable bugsUrl
  @observable vibeUrl
  @observable floUrl
  @observable appleMusicUrl
  @observable amazonMusicUrl
  @observable tidalUrl
  @observable deezerUrl
  @observable jooxUrl
  @observable mymusicUrl
  @observable kkboxUrl
  @observable linejpUrl
  @observable linetwUrl
  @observable yandexUrl
  @observable nctUrl
  @observable zingUrl
  @observable anghmiUrl

  @observable isDisplaySpotifyUrl
  @observable isDisplayYoutubeMusicUrl
  @observable isDisplayMelonDeepLinkUrl
  @observable isDisplayMelonUrl
  @observable isDisplayGenieUrl
  @observable isDisplayBugsUrl
  @observable isDisplayVibeUrl
  @observable isDisplayFloUrl
  @observable isDisplayAppleMusicUrl
  @observable isDisplayAmazonMusicUrl
  @observable isDisplayTidalUrl
  @observable isDisplayDeezerUrl
  @observable isDisplayJooxUrl
  @observable isDisplayMymusicUrl
  @observable isDisplayKkboxUrl
  @observable isDisplayLinejpUrl
  @observable isDisplayLinetwUrl
  @observable isDisplayYandexUrl
  @observable isDisplayNctUrl
  @observable isDisplayZingUrl
  @observable isDisplayAnghmiUrl
  //

  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  @observable albumId
  @observable artistId
  @observable userId
  /* Database fields end */

  @observable releaseArtistList = []
  @observable featuringArtistList = []
  @observable lyricstArtistList = []
  @observable composerArtistList = []
  @observable arrangerArtistList = []
  @observable customUrlInfo
  @observable subdomainInfo
  @observable albumInfo

  @observable isOwner

  @observable trackUrl
  set customUrl(value) {
    // const _customUrl = props?.customUrlInfo?.customUrl || props?.customUrlInfo?.autoUrl || props?._id || ''
    const _customUrl = value

    if (_customUrl) {
      this.trackUrl =
        this?.subdomainInfo?.subdomain && this?.subdomainInfo?.isAccepted
          ? `https://${this.subdomainInfo.subdomain}.${DOMAIN_URL}/${_customUrl}`
          : `https://${DOMAIN_URL}/track/${_customUrl}`
    }
  }

  get releaseArtistName() {
    if (Array.isArray(this.releaseArtistList)) {
      return this.releaseArtistList.map(artist => artist['name']).join(', ')
    }
    return ''
  }

  get audioUri() {
    return `${STORAGE_URL}${this.audioOriginalPath}`
  }

  @observable distributionCodeList
  @observable trendCompanyList

  constructor(stores, props) {
    if (props) {
      this._id = props._id

      this.isTitle = props.isTitle
      this.no = props.no
      this.title = props.title
      this.titleEng = props.titleEng
      this.musicVideoInfoList = props.musicVideoInfoList
      this.audioOriginalPath = props.audioOriginalPath
      this.audioFilename = props.audioFilename
      this.youtubeUrl = props?.youtubeUrl
      this.ISRC = props.ISRC
      this.UCI = props.UCI

      this.releaseArtistIdList = props.releaseArtistIdList
      this.featuringArtistIdList = props.featuringArtistIdList
      this.lyricstArtistIdList = props.lyricstArtistIdList
      this.composerArtistIdList = props.composerArtistIdList
      this.arrangerArtistIdList = props.arrangerArtistIdList
      this.youtubeUrlList = props.youtubeUrlList

      this.lyrics = props.lyrics
      this.mainGenre = props.mainGenre
      this.subGenre = props.subGenre

      //
      this.spotifyUrl = props.spotifyUrl
      this.youtubeMusicUrl = props.youtubeMusicUrl
      this.melonDeepLinkUrl = props.melonDeepLinkUrl
      this.melonUrl = props.melonUrl
      this.genieUrl = props.genieUrl
      this.bugsUrl = props.bugsUrl
      this.vibeUrl = props.vibeUrl
      this.floUrl = props.floUrl
      this.appleMusicUrl = props.appleMusicUrl
      this.amazonMusicUrl = props.amazonMusicUrl
      this.tidalUrl = props.tidalUrl
      this.deezerUrl = props.deezerUrl
      this.jooxUrl = props.jooxUrl
      this.mymusicUrl = props.mymusicUrl
      this.kkboxUrl = props.kkboxUrl
      this.linejpUrl = props.linejpUrl
      this.linetwUrl = props.linetwUrl
      this.yandexUrl = props.yandexUrl
      this.nctUrl = props.nctUrl
      this.zingUrl = props.zingUrl
      this.anghmiUrl = props.anghmiUrl

      this.isDisplaySpotifyUrl = props.isDisplaySpotifyUrl
      this.isDisplayYoutubeMusicUrl = props.isDisplayYoutubeMusicUrl
      this.isDisplayMelonDeepLinkUrl = props.isDisplayMelonDeepLinkUrl
      this.isDisplayMelonUrl = props.isDisplayMelonUrl
      this.isDisplayGenieUrl = props.isDisplayGenieUrl
      this.isDisplayBugsUrl = props.isDisplayBugsUrl
      this.isDisplayVibeUrl = props.isDisplayVibeUrl
      this.isDisplayFloUrl = props.isDisplayFloUrl
      this.isDisplayAppleMusicUrl = props.isDisplayAppleMusicUrl
      this.isDisplayAmazonMusicUrl = props.isDisplayAmazonMusicUrl
      this.isDisplayTidalUrl = props.isDisplayTidalUrl
      this.isDisplayDeezerUrl = props.isDisplayDeezerUrl
      this.isDisplayJooxUrl = props.isDisplayJooxUrl
      this.isDisplayMymusicUrl = props.isDisplayMymusicUrl
      this.isDisplayKkboxUrl = props.isDisplayKkboxUrl
      this.isDisplayLinejpUrl = props.isDisplayLinejpUrl
      this.isDisplayLinetwUrl = props.isDisplayLinetwUrl
      this.isDisplayYandexUrl = props.isDisplayYandexUrl
      this.isDisplayNctUrl = props.isDisplayNctUrl
      this.isDisplayZingUrl = props.isDisplayZingUrl
      this.isDisplayAnghmiUrl = props.isDisplayAnghmiUrl
      //

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt

      this.albumId = props.albumId
      this.artistId = props.artistId
      this.userId = props.userId

      this.customUrlInfo = props.customUrlInfo
      this.subdomainInfo = props.subdomainInfo

      this.releaseArtistList =
        (Array.isArray(props.releaseArtistList)
          && props.releaseArtistList.map(elem => new ArtistModel(stores, elem)))
        || []

      this.featuringArtistList =
        (Array.isArray(props.featuringArtistList)
          && props.featuringArtistList.map(
            elem => new ArtistModel(stores, elem),
          ))
        || []

      this.lyricstArtistList =
        (Array.isArray(props.lyricstArtistList)
          && props.lyricstArtistList.map(elem => new ArtistModel(stores, elem)))
        || []

      this.composerArtistList =
        (Array.isArray(props.composerArtistList)
          && props.composerArtistList.map(
            elem => new ArtistModel(stores, elem),
          ))
        || []

      this.arrangerArtistList =
        (Array.isArray(props.arrangerArtistList)
          && props.arrangerArtistList.map(
            elem => new ArtistModel(stores, elem),
          ))
        || []

      // 회사일 경우, 회사와 연결된 가수 리스트랑 해당 아티스트를 비교해서 권한 검사
      this.isOwner =
        stores.authStore.currentUser.type === 'company'
          ? stores.connectionsStore.artistCompanyConnectionList.filter(
            elem => elem.targetId === props.artistId,
          ).length === 0
            ? false
            : true
          : stores.authStore.currentUser.artistId === props.artistId

      this.trackUrl =
        props
        && props.subdomainInfo
        && props.subdomainInfo.subdomain
        && props.subdomainInfo.isAccepted
          ? props && props.customUrlInfo && props.customUrlInfo.customUrl
            ? `https://${props.subdomainInfo.subdomain}.${DOMAIN_URL}/${props
                && props.customUrlInfo
                && props.customUrlInfo.customUrl}`
            : `https://${props.subdomainInfo.subdomain}.${DOMAIN_URL}/${props
                && props.customUrlInfo
                && props.customUrlInfo.autoUrl}`
          : props && props.customUrlInfo && props.customUrlInfo.autoUrl
            ? `https://${DOMAIN_URL}/track/${props
              && props.customUrlInfo
              && props.customUrlInfo.autoUrl}`
            : `https://${DOMAIN_URL}/track/${props._id}`

      this.distributionCodeList = props.distributionCodeList || []
      this.trendCompanyList = props.trendCompanyList || []

      this.albumInfo = new AlbumModel(stores, props.albumInfo)
    }
  }
}
