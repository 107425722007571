/* 개발서버 셋팅 */
const _IS_DEV_SERVER = false

/* 수정 X */
export const IS_DEV =
  process.env.NODE_ENV === 'dev' || process.env.NODE_ENV === 'development'
const IS_DEV_SERVER = IS_DEV && _IS_DEV_SERVER

export const API_VERSION = 'v1'

export const DOMAIN_URL = IS_DEV ? 'link-test.plam.kr' : `plam.in`

// const IS_DEV = process.env.NODE_ENV === 'development'

// npm start = 개발 서버
// build => 릴리즈 서버
export const API_URL = IS_DEV_SERVER
  ? // ? 'http://localhost:3000'
// ? 'https://0cxv5qckd3.execute-api.ap-northeast-2.amazonaws.com/dev'
  API_VERSION === 'v0'
    ? `https://api-dev.plam.kr`
    : `https://api-${API_VERSION}-dev.plam.kr`
  : API_VERSION === 'v0'
    ? `https://api.plam.kr`
    : `https://api-${API_VERSION}.plam.kr`

export const STORAGE_URL = IS_DEV_SERVER
  ? `https://plam-storage-dev.s3.ap-northeast-2.amazonaws.com`
  : `https://storage.plam.kr`

export const DEFAULT_ARTIST_ID = '5e819326ac4a1175805b9252'

export const SITE_LIST = [
  {
    displayName: 'isDisplaySpotifyUrl',
    name: 'spotifyUrl',
    text: '스포티파이',
  },
  {
    displayName: 'isDisplayYoutubeMusicUrl',
    name: 'youtubeMusicUrl',
    text: '유튜브 뮤직',
  },
  {
    displayName: 'isDisplayMelonDeepLinkUrl',
    name: 'melonDeepLinkUrl',
    text: '멜론 딥링크',
  },
  { displayName: 'isDisplayMelonUrl', name: 'melonUrl', text: '멜론' },
  { displayName: 'isDisplayGenieUrl', name: 'genieUrl', text: '지니' },
  { displayName: 'isDisplayBugsUrl', name: 'bugsUrl', text: '벅스' },
  { displayName: 'isDisplayVibeUrl', name: 'vibeUrl', text: '바이브' },
  { displayName: 'isDisplayFloUrl', name: 'floUrl', text: '플로' },
  {
    displayName: 'isDisplayAppleMusicUrl',
    name: 'appleMusicUrl',
    text: '애플뮤직',
  },
  {
    displayName: 'isDisplayAmazonMusicUrl',
    name: 'amazonMusicUrl',
    text: '아마존뮤직',
  },
  { displayName: 'isDisplayTidalUrl', name: 'tidalUrl', text: '타이달' },
  { displayName: 'isDisplayDeezerUrl', name: 'deezerUrl', text: 'Deezer' },
  { displayName: 'isDisplayJooxUrl', name: 'jooxUrl', text: 'Joox' },
  { displayName: 'isDisplayMymusicUrl', name: 'mymusicUrl', text: 'Mymusic' },
  { displayName: 'isDisplayKkboxUrl', name: 'kkboxUrl', text: 'KKBOX' },
  { displayName: 'isDisplayLinejpUrl', name: 'linejpUrl', text: 'Line JP' },
  { displayName: 'isDisplayLinetwUrl', name: 'linetwUrl', text: 'Line TW' },
  { displayName: 'isDisplayYandexUrl', name: 'yandexUrl', text: 'Yandex' },
  { displayName: 'isDisplayNctUrl', name: 'nctUrl', text: 'NCT' },
  { displayName: 'isDisplayZingUrl', name: 'zingUrl', text: 'Zing MP3' },
  { displayName: 'isDisplayAnghmiUrl', name: 'anghmiUrl', text: 'Anghmi' },
  // { name: 'etcUrl', text: 'adsad' },
]
